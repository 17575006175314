import React from 'react'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import * as styles from './director.module.scss'

export default function Director( props ) {

    const { richTextContent } = props

    return (
        <div className = {styles.container}>
            {renderRichText(richTextContent)}
        </div>
    )
}
