import React from 'react'
import * as styles from './org.module.scss'

export default function Organization( {text, bgImg} ) {
    return (
        <div className = { styles.container }>
            <p>{text}</p>
            <img src = {bgImg.file.url} alt = "" />
        </div>
    )
}
