import React from 'react'
import * as styles from './desc.module.scss'

export default function FeaturesDESC( {title, description} ) {

    return (
        <div className = {styles.container}>

          <h2> {title} </h2>
          <p>  {description} </p>

        </div>
    )
}
