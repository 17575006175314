import React, {useRef, useEffect} from 'react'
import Features from '../../components/Features'
import Layout from '../../layout/Layout'
import { useStaticQuery, graphql } from 'gatsby'
import { gsap, Power3} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import FeaturesDesc from '../../components/aboutPage/featuresDESC/FeaturesDESC'
import Organization from '../../components/aboutPage/organization/Org'
import * as styles from './about.module.scss'
import Director from '../../components/aboutPage/director/Director'

gsap.registerPlugin(ScrollTrigger)

export default function About() {

    let aboutPage = useRef(null)

    useEffect( ()=>{
      gsap.from(aboutPage,{
        scrollTrigger:{
            trigger: aboutPage,
            start: 'top 90%',
            end: 'bottom 70%',
            toggleActions: 'play none none none',
        },
        opacity: 0,
        y:100,
        duration:1.5,
        ease:Power3.easeInOut,
    })
  },[])

    const {headerTitle, metaTitle, director, features, organization } = useStaticQuery(getAboutPage).contentfulAboutPage
    
    return (
        <>
            <Layout title = {headerTitle} metaTitle={metaTitle}>

              
              <section className = {styles.aboutPage}
                       ref = { el => { aboutPage = el }}>

                  <div className = {styles.heroContainer}>
                    <Director richTextContent = {director} />
                    <Organization {...organization} />
                  </div>

                  <div className = {styles.featuresWrapper}>
                    <div className = {styles.featuresContainer}>
                      <FeaturesDesc {...features} />
                      <Features />
                    </div>
                  </div>

                </section>

            </Layout>
        </>
      )
}

const getAboutPage = graphql`
  query aboutPage {
    contentfulAboutPage {
      headerTitle
	  metaTitle
      director {
        raw
      }
      features {
        title
        description
      }
      organization {
        bgImg {
          file {
            url
            fileName
          }
        }
        text
      }
    }
  }
`
